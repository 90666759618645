.section1 {
  margin-top: 100px;
  margin-bottom: 100px;
  .aboutHeading {
    text-align: center;
    font-weight: 600;
    width: 73%;
    color: #3d3c6e;
  }
  .aboutContainer {
    display: flex;
    justify-content: space-around;
    margin-bottom: 50px;
    margin-top: 30px;
    gap: 40px;
    .imageContainer {
      width: 45%;

      .aboutImage {
        width: 100%;
      }
    }

    .content {
      text-align: left;
      width: 48%;
      margin-top: 18px;

      .contentHeading {
        width: 82%;
        margin-top: 20px;
        margin-bottom: 20px;
      }

      .aboutText {
        display: flex;
      }
      .aboutContaint {
        display: flex !important;
      }
      .arrowIcon {
        margin-top: 4px;
        height: 12px;
      }
    }
  }

  .knowMoreButton {
    background-color: #ed1c24;
    border: none;
    border-radius: 25px;
    font-weight: 600;
    width: 170px;
    padding: 10px;
    margin-top: 20px;
    cursor: pointer;
    color: white;
    text-align: center;
  }
}

.section2 {
  .certifiedSection {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      margin: 0 30px;
    }
  }
}
