.Certificate {
  .certificate-container {
    // padding: 50px;
    // width: 1024px;
  }
  .certificate {
    border: 2px solid #0c5280;
    padding: 15px;
    // height: 600px;
    position: relative;
  }

  .certificate:after {
    content: "";
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    position: absolute;
    background-image: url(https://image.ibb.co/ckrVv7/water_mark_logo.png);
    background-size: 100%;
    z-index: -1;
  }

  .certificate-header > .logo {
    width: 80px;
    height: 80px;
  }

  .certificate-title {
    text-align: center;
  }

  .certificate-body {
    text-align: center;
  }

  h1 {
    font-weight: 400;
    font-size: 48px;
    color: #0c5280;
  }

  .student-name {
    font-size: 24px;
    font-weight: 600;
  }

  .certificate-content {
    margin: 0 auto;
    // width: 750px;
  }

  .about-certificate {
    width: 380px;
    margin: 0 auto;
  }

  .topic-description {
    text-align: center;
  }
}
.buttons {
  margin-top: 20px;
  text-align: center;

  button {
    margin: 0 10px;
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;

    &:hover {
      background-color: #0056b3;
    }
  }
}
