.landingCard {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 80px;
  padding: 60px 20px;

  .card {
    flex: 1 1 250px;
    max-width: 300px;
    padding: 10px;
    display: flex;
    flex-direction: column;
  }
  .ant-card-body {
    padding: 0px !important;
    border-radius: 0 0 8px 8px;
  }
  .title {
    font-weight: 700;
    margin: 10px 0 !important;
  }

  .details {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  @media only screen and (max-width: 768px) {
    padding: 10px !important;
    align-items: center !important;
    justify-content: center;
  }
}
