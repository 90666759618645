.Guidelines {
  li,
  h6 {
    // list-style-type: disc;
    margin: 10px 0px;
  }
  h6 {
    font-weight: 700;
  }
  .font_size_17 {
    font-size: 17px;
    font-family: "Poppins", sans-serif !important;
    font-weight: normal;
    color: #1d1d1f;
  }
  .heading {
    font-size: 18px;
    font-weight: 600;
    color: #1d1d1f;
    margin: 16px 0px;
  }
  table {
    margin: 16px 0px;
  }
  h1 {
    text-align: center;
    font-size: 48px;
    margin-bottom: 80px;
  }
  .guidelines-container {
    display: flex;
    width: 100%;
  }
  .guidelines-left {
    // width: 200px;
    width: 20%;
    padding: 10px;
    background-color: #f9f9f914;
    // position: fixed;
    height: 100vh;
    overflow-y: auto;
    border-right: 1px solid gray;
  }
  .guidelines-left ul {
    list-style-type: none;
    padding: 0;
  }
  .guidelines-left li {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .guidelines-left li:hover {
    // background-color: #ddd;
  }
  .guidelines-left li.active {
    // background-color: red;
    border-right: 3px solid #1890ff !important;
    // color: white; /* Change text color to white for better contrast */
  }
  .guidelines-right {
    // margin-left: 220px; /* Account for the fixed left sidebar */
    // padding: 20px;
    padding: 0px 20px ;

    width: 80%;
    background-color: #fff;
    height: 100vh;
    overflow-y: auto;
    scroll-behavior: smooth;
    scrollbar-width: none;
  }
  .guideline-section {
    margin: 50px 0px;
    min-height: 100dvh;
    border-bottom: 1px solid gray;
  }
  .guideline-section h2 {
    margin-top: 0;
  }
}
