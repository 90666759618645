.profile-form {
    background-color: #F7F8F7;
    width: 100%;

    padding: 30px;
    .edit-profile-btn {
      background-color: #ED1C24;
      border-color: #ED1C24;
    }
    .ant-input{
        border-radius: 25px !important;
        padding: 10px;
    }
    .ant-select-selector{
      border-radius: 25px !important;
      padding: 20px !important;
    }
    .Custom-phone-input-style {
      background: #ffffff;
      border-width: 1px;
      border-style: solid;
      border-color: #d9d9d9;
      font-size: 16px;
      font-weight: 600;
      border-radius: 25px !important;
      padding: 10px;
    }
    .edit-profile-btn:hover {
      background-color: #d61a20;/* ProfileForm.scss */
      .profile-form {
        width: 100%;
      
        .ant-row {
          width: 100%;
        }
      
        .ant-col {
          width: 100%;
        }
      
        .edit-profile-btn {
          background-color: #3D3C6E;
          border-color: #3D3C6E;
          margin-top: 20px;
          width: 100%;
        }
      
        .edit-profile-btn:hover {
          background-color: #d61a20;
          border-color: #d61a20;
        }
      }
      
      border-color: #d61a20;
    }
  }