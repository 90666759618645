.customBtn{
    border: none;
    background-color: #ED1C24;
    color: white;
    border-radius: 25px;
    width: 150px;
    padding: 8px;
    font-size: 16px;
    font-weight: 500;
    transition: background-color 0.3s ease;
    &:disabled {
        cursor: not-allowed;
        background-color: #ccc; 
      }
}
.spinner {
    border: 1px solid rgba(255, 255, 255, 0.2);  
    border-radius: 50%;
    border-top: 1px solid white;  
    width: 5px; 
    height: 5px; 
    animation: spin 0.8s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }