.CommonMessage{
    .main_heading {
        display: flex;
        align-items: center;
        gap: 10px;
        color: linear-gradient(269.89deg, #00a556 -56.65%, #0194d9 122.13%);
        font-weight: 700;
        justify-content: space-between;
        .innerheading {
          display: flex;
          align-items: center;
          color: #0294d6;
        }
      }
      .bottomText {
        color: black;
        text-align: center;
        justify-content: center;
        text-decoration: underline;
      }
      .spinner {
        border: 3px solid rgba(0, 0, 0, 0.1);
        border-left-color: #ffffff;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        margin-right: 8px;
        // animation: spin 0.8s linear infinite;
        display: inline-block;
        // vertical-align: middle;
      }
      
      // @keyframes spin {
      //   to {
      //     transform: rotate(360deg);
      //   }
      // }
      
      // button:disabled {
      //   cursor: not-allowed;
      //   opacity: 0.6;
      // }
      
}