.result-container {
    display: flex;
    justify-content: center;
    align-items: center;
    // min-height: 100vh; // Full height for better centering
    // background: linear-gradient(to right, #f0f4f8, #d9e3f0); // Soft background gradient

    .container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .result-card {
        background-color: #fff;
        padding: 2rem;
        border-radius: 10px;
        // box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
        box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
        text-align: center;
        max-width: 400px;
        width: 100%;
        transition: transform 0.2s;

        // &:hover {
        //     transform: scale(1.02); // Slight hover effect
        // }
    }

    .result-card h2 {
        font-size: 28px; // Increased font size
        margin-bottom: 1rem;
        color: #333;
    }

    .result-details p {
        font-size: 18px;
        margin: 0.5rem 0;
        color: #555;
    }

    .final-score {
        font-size: 48px;
        font-weight: 600;
        color: #3d3c6e;
        margin: 1.5rem 0;
    }

    .feedback {
        font-size: 20px;
        color: #6b8e23; // Color for feedback
        font-weight: bold;
        margin-bottom: 1rem;
    }
.btn{
  
    .back-btn {
        background-color: #3d3c6e;
        color: white;
        padding: 0.75rem 1.5rem;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease, transform 0.2s;

        // &:hover {
        //     background-color: #2e2c59;
        //     transform: scale(1.05); // Button hover effect
        // }

        display: flex;
        align-items: center; // Align icon and text
        justify-content: center;
    }
}
}
