.login {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  h6 {
    margin: 20px 0px;
  }
  .corporate-signup {
    text-align: center;
    // margin-bottom: 15px;
    margin: 30px 0px;

    .corporate-link {
      color: #007bff;
      font-weight: bold;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .row {
    width: 100%;
    height: 100%;
  }

  .heading {
    font-weight: 700;
    margin-top: 20px;
  }

  .input_custom {
    border: 1px solid #ed1c24;
    padding: 8px;
    font-size: 16px;
    font-weight: 600;
  }

  .login_input {
    border: 1px solid #dedee7;
    padding: 8px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 25px;
  }

  .ant-radio-group {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
  }

  .ant-radio-wrapper {
    width: 50%;
    position: relative;
    border-radius: 25px;
    border: 2px solid transparent;
    background-color: #f4f5f6;

    .ant-radio-input:checked + .ant-radio-inner {
      border-color: #ed1c24 !important;
    }

    &.ant-radio-wrapper-checked {
      border: 2px solid #ed1c24;
    }
  }

  .ant-radio-wrapper .ant-radio {
    position: absolute;
    right: 0;
    padding-right: 10px;
  }

  .ant-radio-inner {
    border: 2px solid black;
    border-radius: 25px;
  }

  .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    background: black !important;
    border: none !important;
  }

  .leftSide {
    width: 100%;
    position: relative;

    .loginImg {
      object-fit: cover;
      width: 100%;
    }
  }

  .column2 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .rightSide {
      width: 80%;

      .logo {
        margin: 20px 0px;
      }

      .para {
        width: 60%;
        font-size: larger;
        color: #424259;
        opacity: 0.5;
      }

      .forgot_pass {
        margin: 10px 0px;
        cursor: pointer;
        text-decoration: none;
        font-weight: 500;
        justify-self: right !important;
      }
    }

    .bottom_text {
      text-align: center;
      bottom: 30px;
      position: absolute;
      width: 100%;
      font-size: 16px;

      span {
        font-weight: 600;
      }
    }

    .signinBtn {
      background-color: #3d3c6e;
      border-radius: 25px;
    }

    .googleBtn {
      background-color: white;
      color: black;
      width: 100%;
      border-radius: 25px;
      padding: 5px;
      font-weight: 500;
    }
  }

  @media (max-width: 767px) {
    .leftSide {
      display: none;
    }

    .column2 {
      padding: 20px !important;
    }

    .rightSide {
      width: 100% !important;
    }

    .bottom_text {
      bottom: 0px !important;
    }

    .para {
      width: 100%;
    }

    .forgot_pass {
      margin-bottom: 35px;
    }
  }
}
