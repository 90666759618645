html,
body,
#root {
  height: 100%;
  margin: 0;
}
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.content {
  flex: 1;
}
.resend_otp {
  margin: 20px 0px !important;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
  text-align: center !important;
}
.resentlink {
  text-decoration: underline;
  color: blue;
  margin: 20px 0px;
  display: inline-block;
}
body .vp-center {
  background: grey !important;
}
