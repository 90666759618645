.course-ongoing {
  display: flex;
  position: relative;

  .ant-list-item {
    cursor: pointer;

    &.active {
      background-color: #e6f7ff;
    }
  }
  .iframe-container {
    // display: block;
    max-width: 100%;
    height: auto;
  }
  iframe {
    display: block;
    width: 100% !important;
    height: 500px !important;
    background-color: #747679 !important;
  }
  img {
    // width: 500px;
    // height: 500px;
    width: 100%;
    height: 100%;
    display: block;
  }
  .menu-button {
    display: none;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 10;
  }

  .sidebar {
    width: 25%;
    padding: 20px;
    border-radius: 8px;
    margin-right: 20px;
    position: sticky;
    top: 20px;
    align-self: flex-start;

    h2 {
      font-size: 1.5em;
      margin-bottom: 10px;
    }

    .progress {
      margin-bottom: 20px;
    }
    .ant-list {
      max-height: calc(10 * 40px);
      overflow-y: auto;

      /* WebKit browsers */
      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        background: #d9d9d9;
      }

      &::-webkit-scrollbar-thumb {
        background: #d9d9d9;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #d9d9d9;
      }

      /* Firefox */
      scrollbar-width: thin;
      scrollbar-color: #d9d9d9 #f1f1f1;
    }
    .ant-list-item {
      padding: 10px;
      cursor: pointer;
      width: 95%;

      &.active {
        background: #e6f7ff;
        font-weight: bold;
      }
    }
  }

  .content {
    flex: 1;
    padding: 20px;
    background: #fff;
    border-radius: 8px;

    h3 {
      font-size: 1.1em;
      color: #888;
    }

    h1,
    h2 {
      margin-top: 10px;
    }

    p {
      margin-top: 10px;
      line-height: 1.6;
    }

    img {
      margin-top: 20px;
      max-width: 100%;
      border-radius: 8px;
    }

    .ionizingr-radiation {
      background-color: #ed1c240d;
      padding: 15px;
      border-radius: 12px;
      margin-top: 50px;

      .note-section {
        display: flex;
        justify-content: flex-start;
      }

      p {
        margin-bottom: 0px;
        width: 95%;
        margin-left: 10px;
        margin-top: 0px;
      }
    }

    .natural-ground {
      background-color: #f5f5f7;
      padding: 50px;
      margin-top: 50px;
      border-radius: 12px;

      p {
        font-size: 20px;
      }
    }

    .test-knowledge {
      margin-top: 30px;

      .test-form {
        border: 1px solid #e1e1e1;
        padding: 20px;
        border-radius: 12px;

        .form-label {
          font-weight: bold;
          margin-bottom: 20px;
          padding-left: 0px !important;
          margin-left: 10px;
        }

        .custom-radio {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          position: relative;
          padding: 5px;
          border-radius: 5px;
          transition: border-color 0.3s ease-in-out;

          input[type="radio"] {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            margin-right: 10px;

            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;

            border-radius: 50%;
            width: 16px;
            height: 16px;

            border: 2px solid #999;
            transition: 0.2s all linear;
            margin-right: 5px;
            background-color: #fff;
          }

          input[type="radio"]:checked {
            border-width: 6px;
          }

          // &.correctAnswer input[type="radio"]:checked {
          //   border-color: #036444;

          // }

          &.wrongAnswer input[type="radio"]:checked {
            border-color: red;
          }

          label {
            margin-left: 30px;
            font-size: 16px;
          }

          &.correctAnswer {
            border: 2px solid #036444;
            color: #036444;
          }

          &.wrongAnswer {
            border: 2px solid red;
            color: red;
          }

          &.showCorrectAnswer {
            border: 2px solid #036444;
            color: #036444;
          }
        }
      }
    }

    .btn-container {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
    }
  }

  .lecture-item {
    position: relative;
    padding: 10px;
    cursor: pointer;

    &.completed {
      // background-color: #e0ffe0;

      .completed-badge {
        position: absolute;
        right: 5px;
        top: 10px;
        // background: #00ff00;
        // color: #fff;
        color: #019a01;
        padding: 2px 6px;
        border-radius: 4px;
        font-size: 10px;
      }
    }

    &.active {
      background-color: #e6f7ff;
      font-weight: bold;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;

    .sidebar {
      display: none;
    }

    .menu-button {
      display: block;
    }

    .content {
      width: 100%;
    }
  }
}
.ant-modal-content {
  width: 700px !important;
}
.ant-modal-title {
  font-weight: 500 !important;
  font-size: 24px !important;
}
.quizResultContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}
.testName {
  margin: 10px 0px;
  .testName1 {
    // background: #ed1c24;
    color: #ed1c24;
    padding: 5px 0px;
    text-align: center;
    border-radius: 20px;
    font-weight: 600;
    cursor: pointer;
    border: 1px solid #ed1c24;
  }
  .testName1:hover {
    background: #ed1c24;
    color: white;
  }
  .testName1:focus {
    background: #ed1c24; /* Highlight the button when focused */
    color: black;
    outline: 2px solid #ed1c24; /* Add a custom focus outline */
  }
}
.certificate {
  margin: 10px 0px;
  .certificate1 {
    // background: #ed1c24;
    width: 100%;
    color: #3d3c6e;
    padding: 5px 0px;
    text-align: center;
    border-radius: 20px;
    font-weight: 600;
    cursor: pointer;
    border: 1px solid #3d3c6e;
    background: transparent;
  }
  .certificate1:disabled {
    // background-color: #6f6f73;
    cursor: not-allowed;
    // color: white;
    opacity: 0.6;
  }
  .certificate1:hover {
    background: #3d3c6e;
    color: white;
  }
  .certificate1:focus {
    background: #3d3c6e; /* Highlight the button when focused */
    color: white;
    outline: 2px solid #3d3c6e; /* Add a custom focus outline */
  }
}
