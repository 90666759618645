.verify-otp {
  .loginImg {
    height: 80vh;
    width: 100%;
  }

  .column2 {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    .rightSide {
      width: 80%;
      padding: 20px;

      .logo {
        margin: 20px 0px;
      }
      .heading {
        margin: 20px auto;
      }
      .form {
        width: 100%;
      }
    }

    .bottom_text {
      text-align: center !important;
      bottom: 30px !important;
      // position: absolute !important;
      width: 100% !important;
      font-size: 16px;
      span {
        font-weight: 600;
      }
    }

    .signinBtn {
      background-color: #3d3c6e;
      border-radius: 25px;
    }
  }

  .form {
    .input_custom {
      width: 100%;
      padding: 8px;
    }

    .submitBtn {
      width: 100%;
    }
  }
  .resend_otp {
    margin: 10px 0px;
    cursor: pointer;
    text-decoration: none;
    font-weight: 500;
    justify-self: right !important;
  }
}
