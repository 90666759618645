.navbar{
  .navbar-brand img {
    width: 200px;
  }
  
  .navbar-nav .nav-link {
    color: black;
    &:hover {
      color: red;
    }
  }
  
  .navbar-nav .nav-link.active {
    color: red !important;
  }
  
  .navbar-nav .nav-link .fa-shopping-cart {
    font-size: 1.25rem;
  }
  
  .navbar-nav .nav-dropdown {
    .dropdown-menu {
      .dropdown-item {
        &:hover {
          background-color: #f8f9fa;
        }
      }
    }
  }
  .loginBtn{
    background-color: red;
    border: none;
    border-radius: 25px;
    font-weight: 500;
    padding: auto 20px;
}
&::after{
  background-color: rgb(128, 27, 27) !important;
}
}

