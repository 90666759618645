.certificate {
  @media print {
    @page {
      size: auto;

      margin: 20mm;
      counter-increment: none;
      content: none;
    }
  }
  .certificateDetails {
    max-width: 90rem;
    margin: 4.8rem auto;
    border: 0.3rem solid #a36d00a2;
    position: relative;
  }

  .content {
    margin: 4.8rem;
    padding: 3.2rem;
    text-align: center;
    position: relative;
  }

  h1,
  h2,
  .text,
  .participant {
    font-weight: 400;
    margin-bottom: 1.8rem;
  }

  h1 {
    text-transform: uppercase;
    // font-size: 70px;
  }

  h2 {
    font-size: 2rem;
  }

  .text {
    font-size: 1.6rem;
  }

  .participant {
    font-family: "Luxurious Script";
    font-size: 3rem;
  }

  .logo {
    width: 300px;
  }

  .signatures {
    font-family: serif;
    font-size: 1.6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .name {
    margin-bottom: 0.6rem;
  }

  .designation {
    text-transform: uppercase;
  }

  .yblock {
    display: inline-block;
    position: absolute;
    background-color: #fab835;
  }

  .yvertical {
    height: 22rem;
    width: 1.5rem;
  }

  .yhorizontal {
    height: 1.5rem;
    width: 33rem;
  }

  .yellow-v1 {
    top: 0%;
    right: 0%;
    transform: translate(50%, -3.5%);
  }

  .yellow-v2 {
    bottom: 0%;
    left: 0%;
    transform: translate(-50%, 3.5%);
  }

  .yellow-h1 {
    top: 0%;
    right: 0%;
    transform: translate(0%, -50%);
  }

  .yellow-h2 {
    bottom: 0%;
    left: 0%;
    transform: translate(0%, 50%);
  }

  .bblock {
    display: inline-block;
    position: absolute;
    background-color: #000;
  }

  .bvertical {
    height: 34rem;
    width: 5rem;
  }

  .bhorizontal {
    height: 5rem;
    width: 51rem;
  }

  .black-v1 {
    top: 0%;
    right: 0%;
    transform: translate(50%, -7.3%);
  }

  .black-v2 {
    bottom: 0%;
    left: 0%;
    transform: translate(-50%, 7.3%);
  }

  .black-h1 {
    top: 0%;
    right: 0%;
    transform: translate(0%, -50%);
  }

  .black-h2 {
    bottom: 0%;
    left: 0%;
    transform: translate(0%, 50%);
  }
}
