.CourseDetailsNew {
  .CourseDetailsNew_Banner {
    width: 100%;
    height: 400px;
    background: linear-gradient(
        89.74deg,
        rgba(61, 60, 110, 0.8) 0.23%,
        rgba(61, 60, 110, 0.8) 125.07%
      ),
      url("../../assets/coursedeailspic.png") no-repeat center center/cover;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }

  :where(.css-dev-only-do-not-override-apn68).ant-carousel .slick-dots-bottom {
    bottom: 0 !important;
  }
  :where(.css-apn68).ant-carousel .slick-dots {
    margin: 20px 0px !important;
  }

  .slick-dots {
    position: relative !important;
  }
  .About_Section,
  .Course_Curriculm_Section,
  .Reviews_Section {
    margin: 30px 0px;
    h5 {
      margin: 10px 0px;
      margin-bottom: 20px;
    }
  }
  .Reviews_Section {
    .review_box {
      display: flex;
      gap: 30px;
      .review-icon {
        background-color: #f0f0f4;
        border-radius: 50%;
        color: #3d3c6e;
        font-size: 30px;
        padding: 5px 20px;
        font-weight: 600;
      }
      .review-name {
        .name {
          font-family: "Poppins";
          font-size: 18px;
          font-weight: 600;
          line-height: 27px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: #333333;
        }
        .rating {
          margin: 0px 10px;
          color: #f8d90b;
          svg {
            vertical-align: text-bottom !important;
          }
        }
        .value {
          font-family: "Poppins";
          font-size: 14px;
          font-weight: 500;
          line-height: 15px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
        }
      }
      .review_text {
        font-family: "Poppins";
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: #333333;
        text-decoration-skip-ink: none;
      }
    }
    :where(.css-dev-only-do-not-override-apn68).ant-card-bordered {
      margin: 0px 10px;
      border: 1px solid #d9d9d9 !important;
      border-radius: 20px !important;
    }
  }
  .review_box_inner {
    display: flex;
    gap: 10px;
  }
  .ant-card {
    width: 95% !important;
  }
  .Course_card_main {
    width: 100%;

    display: flex;
    gap: 30px;
    .Course_card_left {
      width: 65%;
    }
    .Course_card_right {
      width: 35%;
      margin: 50px 0px;
      .enroll_now_btn {
        background: #ed1c24;
        color: white;
        border: none !important;
        width: 100%;
        border-radius: 20px;
        font-size: small;
        padding: 20px;
      }
    }
  }
  .About_Section {
    p {
      font-family: "Poppins";
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
  }
  .ant-tabs-tab {
    color: #000; // Default color for non-active tabs
  }
  .ant-tabs-tab-active {
    color: red !important; // Text color for the active tab
    .ant-tabs-tab-btn {
      color: red !important;
    }
  }
  .ant-tabs-ink-bar {
    background-color: red !important; // Active tab underline color
  }
  .Review_heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0px;
  }
  .Write_Review_btn {
    border: 2px solid #ed1c24;
    color: #ed1c24;
    width: fit-content;
    padding: 5px 15px;
    cursor: pointer;
    border-radius: 25px;
  }
  .review-actions {
    display: flex;
  }
  .icon_edit,
  .icon_delete {
    cursor: pointer;
  }
}
