.ShowAttempts {
  .score_card {
    margin: 20px 0px;
    border: 1px solid #efefef;
    background-color: #f7f8f7;
    cursor: pointer;
    .section-1{
        display: flex;
        justify-content: space-between;
    }
    .failed_btn{
        background-color: #ED1C24;
        color: white;
        padding: 2px 20px;
        border-radius: 20px;

    }
  }

}
.ShowAttemptsAnswers{
    
}
