.banner {
    display: flex;
    justify-content: space-between;
    background-color: #3d3c6e;
  
    .backgound {
      background-image: url("../../assets/LandingImage/Vector 3.png");
      padding-left: 20px;
  
      .truckImg {
        display: block; // Ensure the truck image is displayed
      }
    }
  
    .bannerContent {
      margin: 50px 0 0 207px;
      color: white;
  
      .contentHeading {
        font-weight: 700;
        width: 74%;
        margin-bottom: 20px;
      }
  
      .bannerText {
        width: 84%;
      }
  
      .bannerBtn {
        background-color: white;
        color: #212121;
        font-weight: 650;
        border-radius: 25px;
        border: none;
        padding: 10px;
        width: 164px;
        margin-top: 20px;
        cursor: pointer;
      }
  
      .icons {
        display: flex;
        width: 90%;
        justify-content: space-between;
        align-items: center;
        margin-top: 50px;
  
        .border {
          border: 1px dashed white !important;
          height: 30px;
        }
  
        .iconText {
          font-size: 14px;
        }
      }
    }
  
    @media only screen and (max-width: 768px) {
      .backgound {
        display: none;
  
        .truckImg {
          display: none;
        }
      }
  
      .bannerContent {
        width: 100% !important;
        margin: 0 !important;
        margin: 10px !important;
  
        .contentHeading {
          width: 100% !important;
        }
  
        .bannerText {
          width: 100%;
        }
  
        .icons {
          width: 100%;
  
          .iconText {
            font-size: 12px;
          }
        }
      }
    }
  
    @media only screen and (max-width: 1450px) {
      .icons {
        width: 90% !important;
      }
    }
  
    @media only screen and (max-width: 1330px) {
      .icons {
        width: 100% !important;
      }
    }
  }
  