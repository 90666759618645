.footer {
    background-color: #f7f7fc; 
    padding: 20px;
    text-align: center;
    margin-top: 20px;
    .footerTop {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #e0e0e0;
        padding-bottom: 25px;
        margin-bottom: 30px;
    }
    
    .logo {
        width: 200px;
    }
    
    .nav {
        display: flex;
        gap: 20px; 
    }
    
    .nav a {
        color: #333; 
        text-decoration: none;
        font-weight: 500;
   
    }
    
    .footerBottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .footerBottom p {
        margin: 0;
    }
    
    .links {
        display: flex;
        gap: 20px;
    }
    @media (max-width: 768px){
        text-align: center !important;
        .footerTop {
            flex-direction: column ;
            align-items: flex-start;
        }
        .nav {
            flex-direction: column;
            align-items: flex-start;
            margin-top: 15px;
        }
        .footerBottom {
            flex-direction: column;
            margin-top: 20px;
            align-items: flex-start;
        }
    }
}

.links a {
    color: #333;
    text-decoration: none;
}
