@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
body {
  margin: 0;

  font-family: "Poppins", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.PhoneInputInput {
  background-color: white !important;
  border: none !important;
}
.PhoneInputInput {
  padding: 0px 10px;
  border-radius: 10px;
  background: transparent !important;
  border: none !important;
}
.PhoneInput {
  input {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    &:focus {
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
    }
  }
}