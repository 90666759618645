.LandingPage {
//   .bannerSection {
//   }
  .section3 {
    margin-top: 100px;
    margin-bottom: 100px;

    .aboutContainer {
      display: flex;
      justify-content: space-around;
      margin-bottom: 50px;
      margin-top: 30px;
      gap: 40px;
      .imageContainer {
        width: 45%;

        .aboutImage {
          width: 100%;
        }
      }

      .content {
        text-align: left;
        width: 48%;
        margin-top: 100px;
      }
      .arrowIcon {
        margin-top: 4px;
        height: 12px;
      }
    }
  }
  .section5 {
    background-color: #f5f5f5;
  }
.section6 {
  margin-top: 100px;
  margin-bottom: 100px;

  .videoContainer {
    background-image: url("../../assets/LandingImage/curve.png");
    margin-top: 240px;
    background-repeat: no-repeat;
    background-size: cover;

    .contentContainer {
      text-align: center;

      .image {
        margin-top: -180px;
        width: 60%;
        height: 50%;
      }

      .text-container {
        color: white;
        width: 72%;
        margin: 50px auto;
      }

      .video-heading {
        margin: 20px auto;
      }

      .video-paragraph {
        margin: 30px auto;
      }
    }
  }

  .custom-container {
    width: 60%;
    display: flex;
    align-items: center;
    margin-top: 30px;
  }

  .custom-paragraph {
    margin: 20px;
  }

  .mobileVideoContainer {
    margin-top: 50px;

    .mobileContentContainer {
      text-align: center;

      .mobileImage {
        width: 100%;
      }

      .mobile-heading {
        color: white;
        width: 100%;
        margin: 20px auto;
        font-size: 1rem;
      }

      .mobile-paragraph {
        color: white;
        margin-bottom: 20px;
        font-size: 0.875rem;
      }
    }
  }
}

.section7 {
    .gridContainer {
      .gridItem {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        margin-bottom: 50px;
        align-items: center;
      }
    }
    .ImageSection {
      padding: 30px;
    }
    .column {
      // margin-top: 60px;

      .headingText {
        width: 67%;
        color: #3d3c6e;
        margin-bottom: 20px;
      }
    }
    .image {
      max-width: 100%;
    //   height: 400px;
    }
  }

  .small {
    text-align: center;
    font-weight: 600;
    color: #ed1c24;
  }

  .text {
    text-align: center;
    font-weight: 600;
    width: 62%;
    color: #3d3c6e;
  }

 .exploreBtn, .knowMoreButton {
    background-color: #ed1c24;
    border: none;
    border-radius: 25px;
    font-weight: 600;
    width: 170px;
    padding: 10px;
    margin-top: 20px;
  }






  @media screen and (min-width: 767px) {
    .section6 {
      .mobileVideoContainer {
        display: none !important;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .section1 {
      .text {
        width: 100% !important;
      }
      .aboutContainer {
        display: flex !important;
        flex-direction: column !important;
        .imageContainer {
          width: 100%;
          .aboutImage {
            width: 100% !important;
          }
        }
        .content {
          width: 100% !important;
          margin-top: 0px !important;
          .contentHeading {
            width: 100%;
          }
          .aboutText {
            margin-top: 10px !important;
          }
        }
      }
    }
    .section2 {
      .certifiedSection {
        flex-direction: column !important;
        align-items: center;
      }
    }
    .section3 {
      margin-top: 0px !important;
      .aboutContainer {
        flex-direction: column;
        width: 100% !important;
        .imageContainer {
          width: 100% !important;
          .aboutImage {
            width: 100% !important;
          }
        }
        .content {
          width: 100% !important;
        }
      }
    }
    .section6 {
      .container {
        width: 100% !important;
        flex-direction: column !important;
        .saftyPractice {
          margin-bottom: 10px !important;
        }
      }
      .videoContainer {
        display: none !important;
      }
      .mobileVideoContainer {
        background-color: #111129;
        .mobileImage {
          width: 100%;
          padding: 10px;
          height: auto;
        }
      }
    }
    .section7 {
      .gridContainer {
        .gridItem {
          display: flex !important;
          flex-direction: column;
          gap: 0px !important;
          .column {
            margin-top: 20px !important;
            .headingText {
              width: 100%;
            }
          }
          .ImageSection {
            padding: 0px !important;
            .image {
              width: 100% !important;
            }
          }
        }
        .reverse {
          flex-direction: column-reverse;
        }
      }
      .image {
        width: 100%;
        height: auto;
      }
    }
    .text {
      width: 100% !important;
    }
    .exploreBtn,
    .knowMoreButton {
      width: 100% !important;
    }
  }
}
