.ForgotPassword {
  height: 100vh;
  overflow: hidden;

  .row {
    display: flex;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0; // Remove any padding that might be causing lines

    .leftSide {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      .loginImg {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .imageOverlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
      }
    }

    .column2 {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      img {
        margin-bottom: 10px;
      }
    }

    .rightSide {
      width: 80%;

      h3 {
        margin-bottom: 20px 0px 20px;
        color: #333333;
        font-size: 24px;
      }
      .input_custom {
        border: 1px solid #dedee7;
        padding: 8px;
        font-size: 16px;
        font-weight: 600;
        border-radius: 25px;
      }
    }
  }

  .custom_radio {
    border: 1px solid #ed1c24;
    padding: 8px;
    font-size: 16px;
    font-weight: 600;
  }
  .ant-radio-group {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
  }

  .ant-radio-wrapper {
    width: 50%;
    position: relative;
    border-radius: 25px;
    border: 2px solid transparent;
    background-color: #f4f5f6;

    .ant-radio-input:checked + .ant-radio-inner {
      border-color: #ed1c24 !important;
    }

    &.ant-radio-wrapper-checked {
      border: 2px solid #ed1c24;
    }
  }

  .ant-radio-wrapper .ant-radio {
    position: absolute;
    right: 0;
    padding-right: 10px;
  }

  .ant-radio-inner {
    border: 2px solid black;
    border-radius: 25px;
  }

  .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    background: black !important;
    border: none !important;
  }
}

@media (max-width: 767px) {
  .ForgotPassword {
    .row {
      .leftSide {
        display: none;
      }
      .column2 {
        padding: 20px;
        overflow: auto;
      }
    }
  }
}
